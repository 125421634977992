'use client'

import {
  Avatar,
  Box,
  Card,
  CardBody,
  Container,
  HStack,
  Heading,
  Link,
  ListItem,
  Text,
  UnorderedList,
  VStack,

} from '@chakra-ui/react'
import { Header } from '../Header'
import { ContactSection } from '../ContactSection'
import PageTitle from '../PageTitle'


export default function ComparativaKlippaRegulaWheelz() {

  return (
    <Box position={'relative'}>
      <PageTitle title={'Comparativa OCR para DNI: Regula vs Klippa vs Wheelz'} />

      <Header />
      <Container
        mb={20}
        maxW={'3xl'}>
        <VStack textAlign={'left'} align={'flex-start'} mt={10}>
          <WhoWeAre />
          <Heading as="h1" mt={5} mb={0} size={'2xl'} lineHeight={1.2}>Comparativa OCR para DNI: Regula vs Klippa vs Wheelz</Heading>
          <Text my={8} fontSize='xl' color={'gray.600'}>
            Un vistazo rápido a las diferencias entre los tres principales proveedores de software OCR para DNI en España.
          </Text>
          <HStack w='100%' mb={10} borderTop={'1px solid'} borderBottom={'1px solid'} borderColor={'gray.200'} py={2}>
            <Avatar name='Raul Vazquez' src='/raul-profile.jpeg' />
            <VStack ms={2} align={'start'} spacing={0}>
              <Text as="strong">Raúl Vazquez</Text>
              <Text>4 Julio 2024</Text>
            </VStack>
          </HStack>
          <Text>
            La tecnología OCR para la extracción de datos de documentos de identidad se ha vuelto esencial en muchos sectores. En este post, compararemos las tres soluciones referentes en extracción de datos de DNI: Regula, Klippa y Wheelz, centrándonos en sus características, precios y beneficios.
          </Text>
          <Heading as="h2" mt={8} mb={4} size={'xl'}>Wheelz</Heading>
          <Text>
            Wheelz es una solución que combina la tecnología OCR con Inteligencia Artificial especializada en la extracción de datos de DNIs españoles. Wheelz destaca por ser el único proveedor capaz de ofrecer una API de fácil integración y por la capacidad de adaptarse al caso de uso del cliente.
          </Text>
          <Heading as="h3" mt={4} mb={2} size={'md'}>Características</Heading>
          <UnorderedList spacing={2}>
            <ListItem>
              Alta precisión en la extracción gracias a la especialización en DNIs.
            </ListItem>
            <ListItem>
              Sin servidores ni costes de puesta en marcha. Es el único que ofrece 100% pago por uso
            </ListItem>
            <ListItem>
              Fácil integración vía API
            </ListItem>
          </UnorderedList>
          <Heading as="h3" mt={4} mb={2} size={'md'}>Precio</Heading>
          <Text>Wheelz ofrece un plan 100% pago por uso que parten desde 0,06€ por documento. Existen descuentos por volumen a partir de 300 documentos mensuales.</Text>
          <Heading as="h2" mt={8} mb={4} size={'xl'}>Regula</Heading>
          <Text>
            Regula es una solución de verificación de identidad que automatiza la lectura y verificación de datos personales de pasaportes, DNI, permisos de conducir, visas y otros documentos de identidad.
          </Text>
          <Heading as="h3" mt={4} mb={2} size={'md'}>Características</Heading>
          <UnorderedList spacing={2}>
            <ListItem>
              Amplio soporte de documentos internacionales.
            </ListItem>
            <ListItem>
              Análisis avanzado de autenticidad y anti-fraude
            </ListItem>
            <ListItem>
              No dispone de API alojada, Se trata de una solución on-premise que debe alojarse en tu propio servidor.
            </ListItem>
          </UnorderedList>
          <Heading as="h3" mt={4} mb={2} size={'md'}>Precio</Heading>
          <Text>La licencia mínima para usar la lectura de DNI es de 8.000€/año por servidor (incluye lecturas ilimitadas). Hay licencias por uso que van desde los 0,25 a 0,14 dólares por documento.</Text>

          <Heading as="h2" mt={8} mb={4} size={'xl'}>Klippa</Heading>
          <Text>
            Klippa ofrece una solución de Procesamiento Automatizado de Documentos y entre sus soluciones ofrece tecnología OCR para diferentes tipos de documentos como DNIs
          </Text>
          <Heading as="h3" mt={4} mb={2} size={'md'}>Características</Heading>
          <UnorderedList spacing={2}>
            <ListItem>
              Amplio soporte de documentos internacionales.
            </ListItem>
            <ListItem>
              Son capaces de ofrecer una validación humana (Human in the Loop) de las extracciones para asegurar y validar los datos de manera más precisa.
            </ListItem>
          </UnorderedList>
          <Heading as="h3" mt={4} mb={2} size={'md'}>Precio</Heading>
          <Text>Para comenzar a trabajar con Klippa se debe hacer frente a un pago inicial de aproximadamente 1.000€ en concepto de puesta en marcha. El coste por documento supera los 0,15€.</Text>
          <Heading as="h2" mt={8} mb={4} size={'xl'}>Comparativa final: ¿Qué API de OCR debería usar para DNI?</Heading>
          <Text>La respuesta depende de tus necesidades y varios factores:</Text>
          <Text mt={2} as={'strong'}>¿Quieres una solución económica y fácil de usar?</Text>
          <Text>Sin ninguna duda, Wheelz es la mejor opción aquí, ya que no requiere servidores o cuotas iniciales. Puedes empezar rápidamente con un plan pago por uso muy inferior al de Regula o Klippa</Text>
          <Text mt={2} as={'strong'}>¿Necesitas verificación de autanticidad?</Text>
          <Text>Regula es la mejor opción, aunque Klippa también tiene esta funcionalidad.</Text>
          <Text mt={2} as={'strong'}>¿Necesitas leer varios tipos de documentos?</Text>
          <Text>Las soluciones de Klippa y Regula soportan multitud de documentos, así que estas serían las mejores opciones</Text>
        </VStack>
      </Container>
      <ContactSection bg={'gray.50'}/>
    </Box>
  )
}

const WhoWeAre = () => {
  return (
    <Card w={'100%'} bgGradient="linear(to-r, red.400,pink.400)" boxShadow='lg' rounded='md' color={'white'}>
      <CardBody>
        <Text><strong>Wheelz es una API especializada en lectura de DNI Español.</strong> <Link color={'white'} textDecor={'underline'} href='/'>Conoce más aquí</Link></Text>
      </CardBody>
    </Card>
  )
}