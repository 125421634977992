'use client'

import {
  Avatar,
  Box,
  Card,
  CardBody,
  Container,
  HStack,
  Heading,
  Image,
  Link,
  ListItem,
  Text,
  UnorderedList,
  OrderedList,
  VStack,

} from '@chakra-ui/react'
import { Header } from '../Header'
import { ContactSection } from '../ContactSection'
import PageTitle from '../PageTitle'


export default function ComparativaKlippaRegulaWheelz() {

  return (
    <Box position={'relative'}>
      <PageTitle title={'¿Qué es la MRZ y por qué es importante en los documentos de identidad?'} />

      <Header />
      <Container
        mb={20}
        maxW={'3xl'}>
        <VStack textAlign={'left'} align={'flex-start'} mt={10}>
          <WhoWeAre />
          <Heading as="h1" mt={5} mb={0} size={'2xl'} lineHeight={1.2}>¿Qué es la MRZ y por qué es importante en los documentos de identidad?</Heading>
          <Text my={8} fontSize='xl' color={'gray.600'}>
            La Zona de Lectura Mecánica o más conocida por sus siglas en inglés cómo MRZ (Machine Readable Zone), es una parte esencial de los pasaportes y documentos de identidad modernos, incluidos los DNIs españoles.<br/><br/> En este post, exploraremos qué es la MRZ, qué información contiene y los beneficios que aporta.
          </Text>
          <HStack w='100%' mb={10} borderTop={'1px solid'} borderBottom={'1px solid'} borderColor={'gray.200'} py={2}>
            <Avatar name='Raul Vazquez' src='/raul-profile.jpeg' />
            <VStack ms={2} align={'start'} spacing={0}>
              <Text as="strong">Raúl Vazquez</Text>
              <Text>22 Julio 2024</Text>
            </VStack>
          </HStack>
          <Heading as="h2" mt={8} mb={4} size={'xl'}>¿Qué es la MRZ?</Heading>
          <Text>
            La MRZ es una serie de líneas alfanuméricas impresas en los documentos de identidad y pasaportes que pueden ser leídas por máquinas y que incluyen información codificada del titular.<br/><br/> Está diseñada para ser interpretada rápidamente por sistemas automatizados, facilitando así la verificación y el procesamiento de los datos del titular del documento. En el DNI español podemos encontrar la MRZ en la parte inferior del reverso del documento:
          </Text>
          <br/>
          <Image src='/mrz.jpg' alt='MRZ DNI Español' />

          <Heading as="h2" mt={8} mb={4} size={'xl'}>¿Qué información contiene la MRZ de un DNI español?</Heading>
          <Text>
          La MRZ de un DNI español incluye múltiple información crucial que permiten la identificación precisa del titular. En el caso del DNI, esta información está codificada en tres líneas con 30 caracteres en cada línea.<br/><br/> Transcripción de la MRZ del DNI anterior:
          </Text>
          <Image src='/mrz_transcript.png' alt='Transcripción DNI Español' />
          <OrderedList spacing={2}>
            <ListItem>Código del tipo de documento: ID en caso del DNI.</ListItem>
            <ListItem>Código del país emisor: Códigos ISO 3166-1 alpha-3. ESP para España.</ListItem>
            <ListItem>Número de soporte: Los siguientes 9 dígitos corresponden al Número de soporte. </ListItem>          
            <ListItem>Dígito de control: Este es un único dígito de control sobre el campo 3.</ListItem>
            <ListItem>Número del documento: A continuación encontramos los 9 dígitos correspondientes al número de DNI. </ListItem>
            <ListItem>Espaciado.</ListItem>
            <ListItem>Fecha de nacimiento: Formato (AAMMDD).</ListItem>
            <ListItem>Dígito de control: Este es un único dígito de control sobre el campo 7.</ListItem>
            <ListItem>Sexo: F (Femenino) o M (Masculino).</ListItem>
            <ListItem>Fecha de expiración del documento: Formato (AAMMDD).</ListItem>
            <ListItem>Dígito de control: Este es un único dígito de control sobre el campo 10.</ListItem>
            <ListItem>Nacionalidad: Códigos ISO 3166-1 alpha-3. ESP para Española. </ListItem>
            <ListItem>Espaciado.</ListItem>
            <ListItem>Dígito de control: Este es un único dígito de control sobre de los campos 3, 4, 5, 7, 8, 10 y 11.</ListItem>
            <ListItem>Nombre completo del titular: Primer apellido &lt; Segundo apellido &lt;&lt; Nombre. </ListItem>
          </OrderedList>
          <Heading as="h2" mt={8} mb={4} size={'l'}>¿Para qué se utilizan los dígitos de control?</Heading>
          <Text>
          Los dígitos de control se utilizan para verificar la integridad y la exactitud de la información contenida en los diferentes campos. Estos dígitos de control se calculan mediante algoritmos específicos y se añaden después de ciertos campos para asegurar que los datos no han sido alterados o ingresados incorrectamente.
          </Text>
          <Heading as="h2" mt={8} mb={4} size={'xl'}>Beneficios de la MRZ</Heading>
          <Text>
            La implementación de la MRZ en documentos de identidad conlleva numerosos beneficios tanto para los usuarios como para las autoridades:
          </Text>
          <UnorderedList spacing={2}>
            <ListItem>
              <Text as="strong">Eficiencia en el procesamiento:</Text> La MRZ permite la lectura rápida y precisa de los datos del documento mediante dispositivos electrónicos, reduciendo el tiempo de espera y el riesgo de errores humanos.
            </ListItem>
            <ListItem>
              <Text as="strong">Seguridad mejorada:</Text> Al incluir datos de control y códigos específicos, la MRZ ayuda a prevenir fraudes y falsificaciones, aumentando la seguridad en la verificación de identidad.
            </ListItem>
            <ListItem>
              <Text as="strong">Estándar internacional:</Text> La MRZ sigue un estándar internacional, lo que facilita el reconocimiento y la autenticación de documentos en diferentes países y sistemas de control fronterizo.
            </ListItem>
            <ListItem>
              <Text as="strong">Integración tecnológica:</Text> Los sistemas modernos de reconocimiento y verificación de identidad, incluidos los basados en OCR (Reconocimiento Óptico de Caracteres) cómo el que ofrece Wheelz, utilizan la MRZ para mejorar la precisión y seguridad de la extracción de los datos del DNI.
            </ListItem>
          </UnorderedList>
          <Heading as="h2" mt={8} mb={4} size={'xl'}>Conclusión</Heading>
          <Text>
            La MRZ es una característica esencial en los documentos de identidad, incluyendo el DNI español, que ofrece eficiencia, seguridad y compatibilidad internacional. Su capacidad para ser leída por máquinas facilita el procesamiento y verificación de datos, haciendo que los procesos de identificación sean más rápidos y seguros.
          </Text>
        </VStack>
      </Container>
      <ContactSection bg={'gray.50'}/>
    </Box>
  )
}

const WhoWeAre = () => {
  return (
    <Card w={'100%'} bgGradient="linear(to-r, red.400,pink.400)" boxShadow='lg' rounded='md' color={'white'}>
      <CardBody>
        <Text><strong>Wheelz es una API especializada en lectura de DNI Español.</strong> <Link color={'white'} textDecor={'underline'} href='/'>Conoce más aquí</Link></Text>
      </CardBody>
    </Card>
  )
}