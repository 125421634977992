import { ChakraProvider } from '@chakra-ui/react'
import { queryClient } from './queryClient'
import { QueryClientProvider } from 'react-query'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import MainPage from './MainPage'
import GetStartedPage from './GetStartedPage'
import BlogPage from './Blog'
import ComparativaKlippaRegulaWheelz from './blog/ComparativaKlippaRegulaWheelz'
import MRZDNIEspanolQueEs from './blog/MRZDNIEspanolQueEs'

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ChakraProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<MainPage />} />
            <Route path="/comenzar" element={<GetStartedPage />} />
            <Route path="/blog" element={<BlogPage />} />
            <Route path="/blog/comparativa-ocr-dni-regula-klippa-wheelz" element={<ComparativaKlippaRegulaWheelz />} />
            <Route path="/blog/que-es-mrz-dni-español" element={<MRZDNIEspanolQueEs />} />
          </Routes>
        </BrowserRouter>
      </ChakraProvider>
    </QueryClientProvider>
  )
}
