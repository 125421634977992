'use client'

import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Flex,
  useColorModeValue,
  Text,
  Container,
} from '@chakra-ui/react'

import { ChevronDownIcon } from '@chakra-ui/icons'

export default function FAQ() {
  return (
    <Flex
      align={'center'}
      justify={'center'}
    >
      <Container p={0}>
        <Accordion defaultIndex={0} width="100%" maxW="lg" rounded="lg">
          <AccordionItem>
            <AccordionButton
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              p={4}>
              <Text fontSize="md">¿Qué es la API de OCR para DNI?</Text>
              <ChevronDownIcon fontSize="24px" />
            </AccordionButton>
            <AccordionPanel pb={4}>
              <Text color="gray.600" mb={2}>
                La API de OCR para DNI <strong>es una herramienta que permite extraer automáticamente la información de un Documento Nacional de Identidad (DNI) español</strong> mediante el uso de técnicas combinadas de Reconocimiento Óptico de Caracteres (OCR) e Inteligencia Artificial (AI).
              </Text>
              <Text color="gray.600">
                Además, <strong>estructura la dirección</strong> para que pueda procesarse más fácilmente.
              </Text>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionButton
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              p={4}>
              <Text fontSize="md">¿Hay un uso mínimo o pago inicial?</Text>
              <ChevronDownIcon fontSize="24px" />
            </AccordionButton>
            <AccordionPanel pb={4}>
              <Text color="gray.600">
                <strong>No. Puedes usar la API de OCR para DNI sin coste inicial ni uso mínimo.</strong> Solo pagas por los DNIs que proceses.
              </Text>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionButton
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              p={4}>
              <Text fontSize="md">¿La API es precisa?</Text>
              <ChevronDownIcon fontSize="24px" />
            </AccordionButton>
            <AccordionPanel pb={4}>
              <Text color="gray.600" mb={2}>
                <strong>La versión básica de la API de OCR para DNI tiene buen rendimiento en imágenes con buena calidad.</strong> Sin embargo, como cualquier tecnología de IA, puede cometer errores en imágenes de baja calidad o con mala iluminación.
              </Text>
              <Text color="gray.600" mb={2}>
                Puedes probar la API de forma gratuita para comprobar su precisión en tu caso de uso. Aún así, <strong>te recomendamos que supervises los resultados en casos de uso críticos.</strong>
              </Text>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionButton
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              p={4}>
              <Text fontSize="md">¿Qué tecnología utiliza?</Text>
              <ChevronDownIcon fontSize="24px" />
            </AccordionButton>
            <AccordionPanel pb={4}>
              <Text color="gray.600">
                La API de OCR para DNI utiliza una <strong>tecnología propia que combina técnicas de OCR e IA</strong> para extraer la información de los DNIs de forma precisa y eficiente.
              </Text>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionButton
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              p={4}>
              <Text fontSize="md">¿Puede adaptarse a mi caso de uso?</Text>
              <ChevronDownIcon fontSize="24px" />
            </AccordionButton>
            <AccordionPanel pb={4}>
              <Text color="gray.600">
                <strong>Por supuesto.</strong> Si tu caso de uso requiere una personalización específica, podemos adaptar la API a tus necesidades.
              </Text>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionButton
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              p={4}>
              <Text fontSize="md">¿Cuánto cuesta?</Text>
              <ChevronDownIcon fontSize="24px" />
            </AccordionButton>
            <AccordionPanel pb={4}>
              <Text color="gray.600">
                <strong>La API de OCR de DNI básica tiene un coste de 0,06€ por cada DNI procesado.</strong> Próximamente lanzaremos una versión con funcionalidades avanzadas que tendrá un coste superior.
              </Text>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionButton
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              p={4}>
              <Text fontSize="md">¿Cómo se usa?</Text>
              <ChevronDownIcon fontSize="24px" />
            </AccordionButton>
            <AccordionPanel pb={4}>
              <Text color="gray.600">
                Para procesar los documentos, <strong>deberás hacer una petición a una API REST que es muy fácil de utilizar.</strong> Cuanto te demos de alta, te proporcionaremos una clave de API y documentación.
              </Text>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Container>
    </Flex>
  )
}