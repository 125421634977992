'use client'

import {
  Box,
  Stack,
  Heading,
  Text,
  Container,
  Button,
  SimpleGrid,
  Spinner,
  Code,
  FormControl,
  Input,
  useToast,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react'
import { useMutation } from 'react-query'
import { ChangeEvent, useState } from 'react'
import { Header } from './Header'
import { CodeBlock, dracula } from 'react-code-blocks'
import { ContactSection } from './ContactSection'
import PageTitle from './PageTitle'


export default function GetStartedPage() {


  const [email, setEmail] = useState('');
  const toast = useToast();

  const { data, isLoading, mutateAsync } = useMutation('generate-test-api-key', async () => {
    const response = await fetch(`${process.env.REACT_APP_ENV === 'dev' ? 'http://localhost:8000' : 'https://api.wheelz-app.es'}/generate-test-api-key`, {
      method: 'POST',
      body: JSON.stringify({ email }),
    });
    if (response.status !== 201) {
      toast({
        title: 'Error',
        description: 'Ha habido un error al generar la clave de API',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      return;
    }
    const key = await response.text();
    return key
  })

  return (
    <Box position={'relative'}>
      <PageTitle title={'Comienza con la API'} />
      <Header />
      <Container
        as={SimpleGrid}
        maxW={'7xl'}
        columns={{ base: 1, md: 2 }}
        spacing={{ base: 10, lg: 32 }}
        py={{ base: 10, sm: 20, lg: 32 }}>
        <Stack spacing={{ base: 10 }}>
          <Heading
            lineHeight={1.1}
            zIndex='1'
            fontSize={{ base: '3xl', sm: '4xl', md: '5xl', lg: '6xl' }}>
            Comienza con la API  <Text as={'span'} bgGradient="linear(to-r, red.400,pink.400)" bgClip="text">
              en 5 minutos
            </Text>
          </Heading>
          <Heading
            display={'flex'}
            alignItems={'center'}
            lineHeight={1.1}
            fontSize={{ base: 'xl' }}>
            <Text as={Box} display={'flex'}
              alignItems={'center'} borderRadius={'100%'} minW={'35px'} height={'35px'} me={3} bgGradient="linear(to-r, red.400,pink.400)"
              color={'white'} justifyContent={'center'}>1</Text>
            <Text>Obtén tu clave de API de prueba con tu e-mail.</Text>
          </Heading>
          <Heading
            display={'flex'}
            alignItems={'center'}
            lineHeight={1.1}
            fontSize={{ base: 'xl' }}>
            <Text as={Box} display={'flex'}
              alignItems={'center'} borderRadius={'100%'} minW={'35px'} height={'35px'} me={3} bgGradient="linear(to-r, red.400,pink.400)"
              color={'white'} justifyContent={'center'}>2</Text>
            <Text>¿Necesitas más créditos de prueba? Sin problema, sólo tienes que escribirnos.</Text>
          </Heading>
          <Heading
            display={'flex'}
            alignItems={'center'}
            lineHeight={1.1}
            fontSize={{ base: 'xl' }}>
            <Text as={Box} display={'flex'}
              alignItems={'center'} borderRadius={'100%'} minW={'35px'} height={'35px'} me={3} bgGradient="linear(to-r, red.400,pink.400)"
              color={'white'} justifyContent={'center'}>3</Text>
            <Text>Cuando estés preparado para pasar a producción, escríbenos para obtener una clave de producción.</Text>
          </Heading>
        </Stack>

        <Stack
          textAlign={'center'}
          bg={'gray.50'}
          rounded={'xl'}
          p={{ base: 4, sm: 6, md: 8 }}
          spacing={{ base: 8 }}
        >
          {
            isLoading ? <Box height={'100%'} display={'flex'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'}>
              <Spinner />
            </Box> : null
          }
          {
            !isLoading && data ? <Box height={'100%'} display={'flex'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'}>
              <Heading
                color={'gray.800'}
                lineHeight={1.1}
                fontSize={{ base: '2xl', sm: '3xl', md: '4xl' }}>
                🎉
              </Heading>
              <Heading
                mt={2}
                color={'gray.800'}
                lineHeight={1.1}
                fontSize={{ base: '2xl', sm: '3xl', md: '4xl' }}>
                ¡Aquí tienes tu clave!
              </Heading>
              <Heading
                mt={10}
                background={'gray.700'}
                color={'white'}
                p={2}
                borderRadius={'md'}
                lineHeight={1.1}
                fontSize={{ base: 'md' }}>
                {data}
              </Heading>
            </Box> : null
          }
          {!isLoading && !data ? (
            <>
              <Stack spacing={4}>
                <Heading
                  color={'gray.800'}
                  lineHeight={1.1}
                  fontSize={{ base: '2xl', sm: '3xl', md: '4xl' }}>
                  Obtén tu clave de API
                </Heading>
                <Text color={'gray.500'} fontSize={{ base: 'sm', sm: 'md' }}>
                  Genera una clave de API de prueba con 50 créditos gratis
                </Text>
              </Stack>
              <Box mt={4} as={'form'}>
                <FormControl>
                  <Input
                    variant={'solid'}
                    borderWidth={1}
                    color={'gray.800'}
                    _placeholder={{
                      color: 'gray.400',
                    }}
                    id={'email'}
                    type={'email'}
                    required
                    placeholder={'Tu email (requerido)'}
                    aria-label={'Tu email (requerido)'}
                    value={email}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                  />
                </FormControl>
                <Button
                  mt={10}
                  onClick={() => email.length > 3 ? mutateAsync() : null}
                  fontFamily={'heading'}
                  w={'full'}
                  bgGradient="linear(to-r, red.400,pink.400)"
                  color={'white'}
                  _hover={{
                    bgGradient: 'linear(to-r, red.400,pink.400)',
                    boxShadow: 'xl',
                  }}>
                  Obtener mi clave de API
                </Button>
              </Box>
            </>
          ) : null}

        </Stack>
      </Container>

      <Box background={'gray.700'}>
        <Container
          maxW={'7xl'}
          py={{ base: 10, sm: 20, lg: 32 }}>
          <Box display={'flex'} alignItems={"center"} justifyContent={'center'} flexDirection={'column'} mb={"3rem"}>
            <Heading
              color={'white'}
              textAlign={'center'}
              lineHeight={1}
              fontSize={{ base: '3xl', sm: '4xl', md: '5xl', lg: '5xl' }}>
              Ejemplo de llamada a la {' '}
              <Text as={'span'} bgGradient="linear(to-r, red.400,pink.400)" bgClip="text">
                API </Text>
            </Heading>
            <Text m={5} color={'gray.300'} fontSize={{ base: 'md', sm: 'lg' }}>
              ¿Necesitas soporte? Ponte en contacto.
            </Text>
          </Box>

          <SimpleGrid
            maxW={'=7xl'}
            columns={2}
            spacing={{ base: 10, lg: 20 }}
          >
            <Box>
              <Tabs variant='soft-rounded' colorScheme='gray'>
                <TabList mb={4}>
                  <Tab>cURL</Tab>
                  <Tab>JavaScript</Tab>
                  <Tab>Python</Tab>
                </TabList>

                <TabPanels>
                  <TabPanel p={0}>
                    <CodeBlock
                      text={curl}
                      language={'javascript'}
                      showLineNumbers={true}
                      theme={dracula}
                    />
                  </TabPanel>
                  <TabPanel p={0}>
                    <CodeBlock
                      text={code}
                      language={'javascript'}
                      showLineNumbers={true}
                      theme={dracula}
                    />
                  </TabPanel>
                  <TabPanel p={0}>
                    <CodeBlock
                      text={python}
                      language={'python'}
                      showLineNumbers={true}
                      theme={dracula}
                    />
                  </TabPanel>
                </TabPanels>
              </Tabs>

            </Box>
            <Box>
              <DisplayJson json={sampleJson} />
            </Box>
          </SimpleGrid>
        </Container>
      </Box>
      <ContactSection />
    </Box>
  )
}

const python = `
import requests

url = "https://api.wheelz-app.es/dni/process"

payload = {}
files=[
  ('files',('anverso.jpeg',open('/Users/demo/anverso.jpeg','rb'),'image/jpeg')),
  ('files',('reverso.jpeg',open('/Users/demo/reverso.jpeg','rb'),'image/jpeg'))
]
headers = {
  'x-api-key': '<api-key>'
}

response = requests.request("POST", url, headers=headers, data=payload, files=files)

print(response.text)
`

const curl = `curl --location 'https://api.wheelz-app.es/dni/process' \n \
  --header 'x-api-key: <api-key>' \n \
  --form 'files=@"dni-anverso.jpeg"' \n \
  --form 'files=@"dni-reverso.jpeg"'`

const sampleJson = {
  documentNumber: "12345678Z",
  name: "Fernando",
  firstSurname: "García",
  secondSurname: "Lozano",
  birthDate: "01/10/1990",
  expirationDate: "20/06/2027",
  gender: "V",
  rawAddress: "Carrer de la Rosa 12 3 2 PALMA DE MALLORCA ISLAS BALEARES",
  parsedAddress: {
    roadType: "Carrer",
    roadName: "De La Rosa",
    number: "12",
    floor: "3",
    door: "2",
    province: "Islas Baleares",
    municipality: "Palma de Mallorca",
    town: "Palma de Mallorca",
  },
}

function DisplayJson({ json }: { json: any }) {
  return (
    <Code h={'100%'} width={'100%'} overflowX={'auto'} whiteSpace={"pre"} padding={'2em'} backgroundColor={"rgba(26,32,44,0.99)"} color={"#FFF"} lineHeight={"2em"} fontSize={".8rem"}
      borderRadius={"5px"} textAlign={'left'}>
      <pre style={{ color: '#FFF' }}>
        {`{`}
        <br />
        <span style={{ color: '#FF9B71' }}>    "documentNumber"</span>
        {`: `}
        <span style={{ color: '#F8F8F2' }}>{json.documentNumber}</span>
        {`,`}
        <br />
        <span style={{ color: '#FF9B71' }}>    "name"</span>
        {`: `}
        <span style={{ color: '#F8F8F2' }}>{json.name}</span>
        {`,`}
        <br />
        <span style={{ color: '#FF9B71' }}>    "firstSurname"</span>
        {`: `}
        <span style={{ color: '#F8F8F2' }}>{json.firstSurname}</span>
        {`,`}
        <br />
        <span style={{ color: '#FF9B71' }}>    "secondSurname"</span>
        {`: `}
        <span style={{ color: '#F8F8F2' }}>{json.secondSurname}</span>
        {`,`}
        <br />
        <span style={{ color: '#FF9B71' }}>    "gender"</span>
        {`: `}
        <span style={{ color: '#F8F8F2' }}>{json.gender}</span>
        {`,`}
        <br />
        <span style={{ color: '#FF9B71' }}>    "birthDate"</span>
        {`: `}
        <span style={{ color: '#F8F8F2' }}>{json.birthDate}</span>
        {`,`}
        <br />
        <span style={{ color: '#FF9B71' }}>    "rawAddress"</span>
        {`: `}
        <span style={{ color: '#F8F8F2' }}>{json.rawAddress}</span>
        {`,`}
        <br />
        <span style={{ color: '#FF9B71' }}>    "structuredAddress"</span>
        {`: {`}
        <br />
        <span style={{ color: '#FF9B71' }}>        "roadType"</span>
        {`: `}
        <span style={{ color: '#F8F8F2' }}>{json.parsedAddress?.roadType}</span>
        {`,`}
        <br />
        <span style={{ color: '#FF9B71' }}>        "roadName"</span>
        {`: `}
        <span style={{ color: '#F8F8F2' }}>{json.parsedAddress?.roadName}</span>
        {`,`}
        <br />
        <span style={{ color: '#FF9B71' }}>        "number"</span>
        {`: `}
        <span style={{ color: '#F8F8F2' }}>{json.parsedAddress?.number}</span>
        {`,`}
        <br />
        <span style={{ color: '#FF9B71' }}>        "floor"</span>
        {`: `}
        <span style={{ color: '#F8F8F2' }}>{json.parsedAddress?.floor}</span>
        {`,`}
        <br />
        <span style={{ color: '#FF9B71' }}>        "door"</span>
        {`: `}
        <span style={{ color: '#F8F8F2' }}>{json.parsedAddress?.door}</span>
        {`,`}
        <br />
        <span style={{ color: '#FF9B71' }}>        "province"</span>
        {`: `}
        <span style={{ color: '#F8F8F2' }}>{json.parsedAddress?.province}</span>
        <br />
        <span style={{ color: '#FF9B71' }}>        "municipality"</span>
        {`: `}
        <span style={{ color: '#F8F8F2' }}>{json.parsedAddress?.municipality}</span>
        <br />
        <span style={{ color: '#FF9B71' }}>        "town"</span>
        {`: `}
        <span style={{ color: '#F8F8F2' }}>{json.parsedAddress?.town}</span>
        <br />
        {`    }`}
        <br />
        {`}`}
      </pre>
    </Code>
  );
}

const code = `
const axios = require('axios');
const FormData = require('form-data');
const fs = require('fs');
let data = new FormData();
// También puede enviar una sola imagen con ambas caras
data.append('files', fs.createReadStream('/Users/demo/anverso.jpeg'));
data.append('files', fs.createReadStream('/Users/demo/reverso.jpeg'));

let config = {
  method: 'post',
  maxBodyLength: Infinity,
  url: 'https://api.wheelz-app.es/dni/process',
  headers: { 
    'x-api-key': '<api-key>', 
    ...data.getHeaders()
  },
  data : data
};

axios.request(config)
  .then((response) => {
    console.log(JSON.stringify(response.data));
  })
  .catch((error) => {
    console.log(error);
  });
`