import { Box, Button, Container, Flex, HStack, Heading, Link } from "@chakra-ui/react"

export const Header = () => {

    return (
        <Box backgroundColor={'gray.50'} height={'60px'} display={'flex'} alignItems={'center'} justifyContent={'space-between'} padding={'0 24px'}>
            <Container
                as={Flex}
                justifyContent={'space-between'}
                alignItems={'center'}
                maxW={'7xl'}>
                <HStack>
                    <Link href="/"><Heading color={'gray.700'} as='h1' size='md' noOfLines={1} me={10}>
                        Wheelz
                    </Heading></Link>
                    <Link fontWeight={600} me={5} href={'/'} color={'red.400'}>Inicio</Link>
                    <Link fontWeight={600} me={5} target="_blank" href={'https://wool-amaranthus-c35.notion.site/Wheelz-API-Docs-v-1-0-0-8a87d1e29dfd4a81896a1b0c6d3ca280'} color={'red.400'}>API Docs</Link>
                    <Link fontWeight={600} me={5} href={'/blog'} color={'red.400'}>Blog</Link>
                    {/*<Link href={'/comenzar'} color={'gray.600'}>Comenzar</Link>*/}
                </HStack>
                <HStack>
                    <Button
                        as={'a'}
                        href="/comenzar"
                        fontFamily={'heading'}
                        w={'full'}
                        bgGradient="linear(to-r, red.400,pink.400)"
                        color={'white'}
                        _hover={{
                            bgGradient: 'linear(to-r, red.400,pink.400)',
                            boxShadow: 'xl',
                        }}>
                        Comenzar
                    </Button>
                    <Button
                        as={'a'}
                        href="#contactar"
                        fontFamily={'heading'}
                        w={'full'}
                        bgGradient="linear(to-r, gray.300,gray.400)"
                        color={'white'}
                        _hover={{
                            bgGradient: 'linear(to-r, red.400,pink.400)',
                            boxShadow: 'xl',
                        }}>
                        Contactar
                    </Button>
                </HStack>
            </Container>

        </Box>
    )
}