import { Box, Heading, Link, Text } from "@chakra-ui/react"

export const ContactSection = (props: {bg?: string}) => {

    return (<Box bg={props.bg || 'white'} id="contactar" textAlign="center" py={{ base: 10, sm: 20, lg: 32 }}>
        <Heading
            textAlign={'center'}
            lineHeight={1}
            fontSize={{ base: '3xl', sm: '4xl', md: '5xl', lg: '5xl' }}>
            ¿Dudas? {' '}
            <Text as={'span'} bgGradient="linear(to-r, red.400,pink.400)" bgClip="text">
                Escríbenos </Text>
        </Heading>
        <Text mt={10} color={'gray.500'} fontSize={{ base: 'xl' }}>
            Escríbenos a <Link color='red.400' href="mailto:hola@wheelz-app.es">hola@wheelz-app.es</Link>
        </Text>
        <Text mt={5} color={'gray.500'}>Te responderemos tan pronto como podamos :)</Text>
    </Box>)

}