import {
    Box,
    chakra,
    SimpleGrid,
    Stat,
    StatLabel,
    StatNumber,
    useColorModeValue,
  } from '@chakra-ui/react'
  
  interface StatsCardProps {
    title: string
    stat: string
  }
  function StatsCard(props: StatsCardProps) {
    const { title, stat } = props
    return (
      <Stat
        px={{ base: 4, md: 8 }}
        py={'5'}
        shadow={'xl'}
        border={'1px solid'}
        borderColor={useColorModeValue('gray.800', 'gray.500')}
        rounded={'lg'}>
        <StatLabel fontWeight={'medium'} isTruncated>
          {title}
        </StatLabel>
        <StatNumber fontSize={'2xl'} fontWeight={'medium'}>
          {stat}
        </StatNumber>
      </Stat>
    )
  }
  
  export default function BasicStatistics() {
    return (
      <Box py={{ base: 10, sm: 20, lg: 32 }} maxW="7xl" mx={'auto'}>
        <chakra.h1 textAlign={'center'} fontSize={'5xl'} py={10} fontWeight={'bold'}>
          En qué nos diferenciamos
        </chakra.h1>
        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={{ base: 5, lg: 8 }}>
          <StatsCard title={'Sin coste inicial'} stat={'Sin costes de puesta en marcha ni permanencia. Paga sólo por lo que usas.'} />
          <StatsCard title={'Totalmente gestionado'} stat={'Sin servidores. Simplemente usa nuestra API.'} />
          <StatsCard title={'Bajo coste'} stat={'Somos la API especializada en DNI Español más económica del mercado'} />
        </SimpleGrid>
      </Box>
    )
  }