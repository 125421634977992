'use client'

import {
  Avatar,
  Box,
  Card,
  CardBody,
  CardHeader,
  Container,
  Flex,
  Heading,
  Image,
  Link,
  SimpleGrid,
  Text,

} from '@chakra-ui/react'
import { Header } from './Header'
import { ContactSection } from './ContactSection'
import PageTitle from './PageTitle'


export default function BlogPage() {

  return (
    <Box position={'relative'}>
      <PageTitle title={'Blog'} />
      <Header />
      <Container
        maxW={'7xl'} my={20}>
        <SimpleGrid columns={3} spacing={4}>
          <Article img="/dni.png" author={'raul'} title='Comparativa OCR para DNI: Regula vs Klippa vs Wheelz' content='Un vistazo rápido a las diferencias entre los tres principales proveedores de software OCR para DNI en España.' url="/blog/comparativa-ocr-dni-regula-klippa-wheelz"></Article>
          <Article img="/mrz.jpg" author={'raul'} title='¿Qué es la MRZ de un DNI Español?' content='Conoce qué es la MRZ (Machine Readable Zone), que información contiene y por qué es importante en los DNIs ' url="/blog/que-es-mrz-dni-español"></Article>
        </SimpleGrid>
      </Container>
      <ContactSection  bg={'gray.50'} />
    </Box>
  )
}

interface ArticleProps {
  title: string
  content: string
  url: string
  author: 'raul' | 'albert'
  img: string
}
const Article = (props: ArticleProps) => {
  return (
    <Link href={props.url}>
      <Card maxW='md'>
        <CardHeader pb={0}>
          <Heading size='lg' as="h2" mb={0}>{props.title}</Heading>
        </CardHeader>
        <CardBody>
          <Text>
            {props.content}
          </Text>
          <Flex mt={5}>
            {
              props.author === 'raul' ? (
                <Flex flex='1' gap='4' alignItems='center' flexWrap='wrap'>
                  <Avatar height={'35px'} width={'35px'} name='Raul Vazquez' src='/raul-profile.jpeg' />
                  <Box>
                    <Heading size='sm'>Raúl Vazquez</Heading>
                    <Text>Founder @ Wheelz</Text>
                  </Box>
                </Flex>
              ) : null
            }
            {
              props.author === 'albert' ? (
                <Flex flex='1' gap='4' alignItems='center' flexWrap='wrap'>
                  <Avatar height={'35px'} width={'35px'} name='Segun Adebayo' src='/albert-image.jpeg' />
                  <Box>
                    <Heading size='sm'>Albert Vazquez</Heading>
                    <Text>Founder @ Wheelz</Text>
                  </Box>
                </Flex>
              ) : null
            }
          </Flex>
        </CardBody>
        <Image
          height={'250px'}
          objectFit='cover'
          src={props.img}
          alt={props.title}
        />
      </Card>
    </Link>
  )
}