'use client'

import {
  Box,
  Stack,
  Heading,
  Text,
  Container,
  Button,
  SimpleGrid,
  HStack,
  Tag,
  TagLabel,
  TagLeftIcon,
  Spinner,
  Code,
  Image,
  VStack,
  List,
  ListIcon,
  ListItem,
  useColorModeValue,
  Badge,
} from '@chakra-ui/react'
import FAQ from './FAQ'
import { useMutation } from 'react-query'
import { useState } from 'react'
import { DemoDropZone } from './DemoDropZone'
import { AttachmentIcon } from '@chakra-ui/icons'
import { ReactNode } from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import { Header } from './Header'
import BasicStatistics from './Benefits'
import { ContactSection } from './ContactSection'


export default function MainPage() {

  const [files, setFiles] = useState<File[]>([]);

  const { data, isLoading, mutateAsync, reset } = useMutation('upload', async () => {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append('files', file);
    });

    (window as Window & any).gtag('event', 'conversion', {
      'send_to': 'AW-11392480374/wBV-CIKTtbQZEPborbgq',
      'value': 1.0,
      'currency': 'EUR',
    });

    const response = await fetch(`${process.env.REACT_APP_ENV === 'dev' ? 'http://localhost:8000' : 'https://api.wheelz-app.es'}/dni/process-demo`, {
    method: 'POST',
    body: formData,
  });
  const json = await response.json();
  return json
})

return (
  <Box position={'relative'}>
    <Header />
    <Container
      as={SimpleGrid}
      maxW={'7xl'}
      columns={{ base: 1, md: 2 }}
      spacing={{ base: 10, lg: 32 }}
      py={{ base: 10, sm: 20, lg: 32 }}>
      <Stack spacing={{ base: 10 }}>
        <Heading
          lineHeight={1.1}
          zIndex='1'
          fontSize={{ base: '3xl', sm: '4xl', md: '5xl', lg: '6xl' }}>
          API de lectura de <Text as={'span'} bgGradient="linear(to-r, red.400,pink.400)" bgClip="text">
            DNI Español
          </Text>
        </Heading>
        <Text fontSize='xl' fontWeight='bold'>
          <Badge textTransform={'none'} ml='1' variant='outline' fontSize='0.8em' colorScheme='red'>
            Tecnología OCR + IA Exclusiva
          </Badge>
        </Text>
        <FAQ />
      </Stack>

      <Stack
        textAlign={'center'}
        bg={'gray.50'}
        rounded={'xl'}
        p={{ base: 4, sm: 6, md: 8 }}
        spacing={{ base: 8 }}
      >
        {
          isLoading ? <Box height={'100%'} display={'flex'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'}>
            <Spinner />
          </Box> : null
        }
        {
          !isLoading && data ? <Box height={'100%'} display={'flex'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'}>
            <Heading
              color={'gray.800'}
              lineHeight={1.1}
              fontSize={{ base: '2xl', sm: '3xl', md: '4xl' }}>
              🎉
            </Heading>
            <Heading
              mt={2}
              color={'gray.800'}
              lineHeight={1.1}
              fontSize={{ base: '2xl', sm: '3xl', md: '4xl' }}>
              ¡Ya tenemos resultados!
            </Heading>
            <Text maxW={'100%'} mt={10} color={'gray.500'} fontSize={{ base: 'sm', sm: 'md' }}>
              <DisplayJson json={data || {}} />
            </Text>
            <Button
              onClick={() => reset()}
              fontFamily={'heading'}
              mt={10}
              color={'gray'}
            >
              Volver a empezar
            </Button>
          </Box> : null
        }
        {!isLoading && !data ? (
          <>
            <Stack spacing={4}>
              <Text fontSize='xl' fontWeight='bold'>
                <Badge textTransform={'none'} ml='1' variant='outline' fontSize='0.8em' colorScheme='red'>
                  Procesamiento Básico
                </Badge>
              </Text>
              <Heading
                color={'gray.800'}
                lineHeight={1.1}
                fontSize={{ base: '2xl', sm: '3xl', md: '4xl' }}>
                Prueba nuestra API
              </Heading>
              <Text color={'gray.500'} fontSize={{ base: 'sm', sm: 'md' }}>
                Puedes subir dos imágenes (una por cada cara) o una sola imagen con ambas caras. Soportamos JPEG, PNG y PDF
              </Text>
            </Stack>
            <DemoDropZone onAcceptedFilesChange={(files => setFiles(files))} />
            <HStack wrap={"wrap"} spacing={4}>
              {files.map((file) => (
                <Tag
                  size={'lg'}
                  key={file.name}
                  borderRadius='md'
                  variant='solid'
                  colorScheme='cyan'
                >
                  <TagLeftIcon boxSize='12px' as={AttachmentIcon} />
                  <TagLabel>{file.name}</TagLabel>
                </Tag>
              ))}
            </HStack>
            <Box as={'form'}>
              <Button
                onClick={() => mutateAsync()}
                disabled={files.length === 0}
                fontFamily={'heading'}
                w={'full'}
                bgGradient="linear(to-r, red.400,pink.400)"
                color={'white'}
                _hover={{
                  bgGradient: 'linear(to-r, red.400,pink.400)',
                  boxShadow: 'xl',
                }}>
                Procesar
              </Button>
            </Box>
          </>
        ) : null}

      </Stack>
    </Container>

    <Box background={'gray.100'}>
      <Container
        maxW={'7xl'}
        py={{ base: 10, sm: 20, lg: 32 }}>
        <Box display={'flex'} alignItems={"center"} justifyContent={'center'} flexDirection={'column'} mb={"3rem"}>
          <Heading
            textAlign={'center'}
            lineHeight={1}
            fontSize={{ base: '3xl', sm: '4xl', md: '5xl', lg: '5xl' }}>
            La {' '}
            <Text as={'span'} bgGradient="linear(to-r, red.400,pink.400)" bgClip="text">
              API </Text>
            más sencilla para OCR de DNI
          </Heading>
          <Text m={5} color={'gray.500'} fontSize={{ base: 'md', sm: 'lg' }}>
            Nuestra tecnología de OCR de DNI funciona con la última IA y es capaz de extraer datos de cualquier imagen de DNI Español.
          </Text>
        </Box>

        <SimpleGrid
          maxW={'=7xl'}
          columns={2}
          spacing={{ base: 10, lg: 32 }}
        >
          <Box>
            <Image borderRadius={"5px"} height={600} width={'100%'} objectFit='cover' src={process.env.PUBLIC_URL + '/dni.png'}></Image>
          </Box>
          <Box>
            <DisplayJson json={sampleJson} />
          </Box>
        </SimpleGrid>
      </Container>
    </Box>

    <BasicStatistics />
    <ThreeTierPricing></ThreeTierPricing>
    <ContactSection />
  </Box>
)
}

function PriceWrapper({ children }: { children: ReactNode }) {
  return (
    <Box
      mb={4}
      shadow="base"
      background={'white'}
      borderWidth="1px"
      alignSelf={{ base: 'center', lg: 'flex-start' }}
      borderColor={useColorModeValue('gray.200', 'gray.500')}
      borderRadius={'xl'}>
      {children}
    </Box>
  );
}

function ThreeTierPricing() {
  return (
    <Box background={'red.50'} py={{ base: 10, sm: 20, lg: 32 }}>
      <VStack spacing={2} textAlign="center">
        <Heading as="h1" fontSize={{ base: '3xl', sm: '4xl', md: '5xl', lg: '5xl' }}>
          Precios de API flexibles
        </Heading>
        <Text fontSize="lg" m={5} color={'gray.500'}>
          Paga sólo por lo que usas. Sin costes iniciales. Pruébalo gratis.
        </Text>
      </VStack>
      <Stack
        direction={{ base: 'column', md: 'row' }}
        textAlign="center"
        justify="center"
        spacing={{ base: 4, lg: 10 }}
        py={10}>
        <PriceWrapper>
          <Box py={4} px={12}>
            <Text fontWeight="500" fontSize="2xl">
              Básico
            </Text>
            <HStack justifyContent="center">
              <Text fontSize="5xl" fontWeight="900">
                0,06
              </Text>
              <Text fontSize="3xl" fontWeight="600">
                €
              </Text>
            </HStack>
            <Text color="gray.500">por documento</Text>
          </Box>
          <VStack
            bg={useColorModeValue('gray.50', 'gray.700')}
            py={4}
            borderBottomRadius={'xl'}>
            <List spacing={3} textAlign="start" px={12}>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                Funciona con cualquier imagen
              </ListItem>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                Estructuración de dirección
              </ListItem>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                Sin costes iniciales
              </ListItem>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                Número DNI verificado
              </ListItem>
            </List>
            <Box w="80%" pt={7}>
              <Button
                as={'a'}
                href="/comenzar" w="full" colorScheme="red">
                Comenzar
              </Button>
            </Box>
          </VStack>
        </PriceWrapper>

        <PriceWrapper>
          <Box position="relative">
            <Box
              position="absolute"
              top="-16px"
              left="50%"
              style={{ transform: 'translate(-50%)' }}>
              <Text
                textTransform="uppercase"
                bg={useColorModeValue('red.300', 'red.700')}
                px={3}
                py={1}
                color={useColorModeValue('gray.900', 'gray.300')}
                fontSize="sm"
                fontWeight="600"
                rounded="xl">
                Próximamente
              </Text>
            </Box>
            <Box py={8} px={12}>
              <Text fontWeight="500" fontSize="2xl">
                Avanzado
              </Text>
            </Box>
            <VStack
              bg={useColorModeValue('gray.50', 'gray.700')}
              py={4}
              borderBottomRadius={'xl'}>
              <List spacing={3} textAlign="start" px={12}>
                <ListItem>
                  <ListIcon as={FaCheckCircle} color="green.500" />
                  Recorte y encuadrado de la imagen
                </ListItem>
                <ListItem>
                  <ListIcon as={FaCheckCircle} color="green.500" />
                  Validación de datos mediante MRZ
                </ListItem>
              </List>
              <Box w="80%" pt={7}>
                <Button as={'a'} href="#contactar" w="full" colorScheme="gray" variant="outline">
                  Me interesa
                </Button>
              </Box>
            </VStack>
          </Box>
        </PriceWrapper>
      </Stack>
    </Box>
  );
}

const sampleJson = {
  documentNumber: "12345678Z",
  name: "Fernando",
  firstSurname: "García",
  secondSurname: "Lozano",
  birthDate: "01/10/1990",
  expirationDate: "20/06/2027",
  gender: "V",
  rawAddress: "Carrer de la Rosa 12 3 2 PALMA DE MALLORCA ISLAS BALEARES",
  parsedAddress: {
    roadType: "Carrer",
    roadName: "De La Rosa",
    number: "12",
    floor: "3",
    door: "2",
    province: "Islas Baleares",
    municipality: "Palma de Mallorca",
    town: "Palma de Mallorca",
  },
}

function DisplayJson({ json }: { json: any }) {
  return (
    <Code h={'100%'} width={'100%'} overflowX={'auto'} whiteSpace={"pre"} padding={'2em'} backgroundColor={"rgba(26,32,44,0.99)"} color={"#FFF"} lineHeight={"2em"} fontSize={".8rem"}
      borderRadius={"5px"} textAlign={'left'}>
      <pre style={{ color: '#FFF' }}>
        {`{`}
        <br />
        <span style={{ color: '#FF9B71' }}>    "documentNumber"</span>
        {`: `}
        <span style={{ color: '#F8F8F2' }}>{json.documentNumber}</span>
        {`,`}
        <br />
        <span style={{ color: '#FF9B71' }}>    "name"</span>
        {`: `}
        <span style={{ color: '#F8F8F2' }}>{json.name}</span>
        {`,`}
        <br />
        <span style={{ color: '#FF9B71' }}>    "firstSurname"</span>
        {`: `}
        <span style={{ color: '#F8F8F2' }}>{json.firstSurname}</span>
        {`,`}
        <br />
        <span style={{ color: '#FF9B71' }}>    "secondSurname"</span>
        {`: `}
        <span style={{ color: '#F8F8F2' }}>{json.secondSurname}</span>
        {`,`}
        <br />
        <span style={{ color: '#FF9B71' }}>    "gender"</span>
        {`: `}
        <span style={{ color: '#F8F8F2' }}>{json.gender}</span>
        {`,`}
        <br />
        <span style={{ color: '#FF9B71' }}>    "birthDate"</span>
        {`: `}
        <span style={{ color: '#F8F8F2' }}>{json.birthDate}</span>
        {`,`}
        <br />
        <span style={{ color: '#FF9B71' }}>    "rawAddress"</span>
        {`: `}
        <span style={{ color: '#F8F8F2' }}>{json.rawAddress}</span>
        {`,`}
        <br />
        <span style={{ color: '#FF9B71' }}>    "structuredAddress"</span>
        {`: {`}
        <br />
        <span style={{ color: '#FF9B71' }}>        "roadType"</span>
        {`: `}
        <span style={{ color: '#F8F8F2' }}>{json.parsedAddress?.roadType}</span>
        {`,`}
        <br />
        <span style={{ color: '#FF9B71' }}>        "roadName"</span>
        {`: `}
        <span style={{ color: '#F8F8F2' }}>{json.parsedAddress?.roadName}</span>
        {`,`}
        <br />
        <span style={{ color: '#FF9B71' }}>        "number"</span>
        {`: `}
        <span style={{ color: '#F8F8F2' }}>{json.parsedAddress?.number}</span>
        {`,`}
        <br />
        <span style={{ color: '#FF9B71' }}>        "floor"</span>
        {`: `}
        <span style={{ color: '#F8F8F2' }}>{json.parsedAddress?.floor}</span>
        {`,`}
        <br />
        <span style={{ color: '#FF9B71' }}>        "door"</span>
        {`: `}
        <span style={{ color: '#F8F8F2' }}>{json.parsedAddress?.door}</span>
        {`,`}
        <br />
        <span style={{ color: '#FF9B71' }}>        "province"</span>
        {`: `}
        <span style={{ color: '#F8F8F2' }}>{json.parsedAddress?.province}</span>
        <br />
        <span style={{ color: '#FF9B71' }}>        "municipality"</span>
        {`: `}
        <span style={{ color: '#F8F8F2' }}>{json.parsedAddress?.municipality}</span>
        <br />
        <span style={{ color: '#FF9B71' }}>        "town"</span>
        {`: `}
        <span style={{ color: '#F8F8F2' }}>{json.parsedAddress?.town}</span>
        <br />
        {`    }`}
        <br />
        {`}`}
      </pre>
    </Code>
  );
}

